:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;

}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  border-radius: 10px;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 275ms ease-out;
  background: #fff;
  color: #242424;
}

.sign-Up-btn--outline {
  background-color: transparent;
  color: #000;
  padding: 8px 20px;
  border: 1px solid #000;
  transition: all 0.3s ease-out;
  border-radius: 10px;
}

.sign-Up-btn--medium {
  padding: 8px 20px;
  font-size: 18px;
  max-height: 70px;
  min-width: 112px;

}

.sign-Up-btn--medium:hover {
  transition: all 275ms ease-out;
  background: #000;
  color: #fff;
}

.search-btn--outline {
  background-color: transparent;
  color: #000;
  padding: 14px 20px;
  border: 1px solid #000;
  transition: all 0.3s ease-out;
  border-radius: 10px;
}

.search-btn--medium {
  padding: 14px 20px;
  font-size: 18px;
}

.search-btn--medium:hover {
  transition: all 275ms ease-out;
  background: #fff;
  color: #000;
}

.left{
  font-size: 16px;
  background-color: #000;
  color: #fff;
  padding: 14px 20px;
  border-radius: 10px;
}

.right{
  font-size: 16px;
  background-color: #fff;
  color: #000;
  padding: 14px 20px;
  border-radius: 10px;
}
