.txt_cards_column{
    float: left;
    width: 50%;
    padding: 0 0.7rem;
}
.cards__item__inner__text{
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
}

.cards__item__h5 {
  font-size: 30px;
  padding: 10px;
}

.cards__item__p {
  font-size: 20px;
  padding: 0 10px;
}

.learn-more{
    padding: 45px 10px 10px 10px;
}

@media screen and (max-width: 900px) {
    .txt_cards_column {
        width: 100%;
        display: block;
        margin-bottom: 4vw;
    }
    .cards__item__inner__text{
        position: absolute;
        top: 50%;
        text-align: center;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .cards__item__h5 {
        font-size: 25px;
        padding: 5px;
    }

    .cards__item__p {
        font-size: 12px;
        padding: 0 5px;
    }
}