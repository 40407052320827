video{
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('/images/img-home.jpg') center center/cover no-repeat;
  height: 78vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -95px;
  overflow: hidden;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  position: relative;
}

.hero-btns {
  margin: 25px 6vw;
}

.hero-btns > .btn + .btn {
  margin-left: 10px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 820px) {
  .hero-btns> .btn {
    width: 60%;
  }
  .hero-btns > .btn + .btn {
    margin: 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }
}
