.cards {
  margin: 3vw 6vw 0;
}

.cards__container {
  width: 100%;
  margin-top: 3vw;
}

.cards__item {
  list-style-type: none;
}

.cards__item__link {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
  height: 40vh;

}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.pre--image {
  border-radius: 10px;
  overflow: hidden;
}

.cards__item__text {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}


.cards_column {
  float: left;
  width: 25%;
  padding: 0 0.7rem;
}

.cards_row {margin: 0 -0.8rem;}

.cards_row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 900px) {
  .cards_column {
    width: 50%;
    display: block;
    padding-bottom: 2vw;
  }
}

@media screen and (max-width: 409px) {
  .cards_column {
    width: 100%;
    display: block;
  }
}
