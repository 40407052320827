.pageContainer {
    margin-left: 6vw;
    margin-right: 6vw;
    margin-top: 2vw;
}

.pageTitle {
    width: 40%;
    height: 40px;
}
.pageTitle > h2 {
    position: absolute;
    font-size: 30px;
}

.pageLocationPrice {
    width: 30%;
    height: 25px;
    margin-bottom: 2vw;
}

.pageCarousel {
    height: 40vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.carouselImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.pageDetails {
    margin-top: 2vw;
}
.pageDetails_list {
    list-style-type: none;
    margin-top: 1vw;
}

.pageDescription {
    margin-top: 2vw;
}

.pageDescription_txt {
    margin-top: 1vw;
}

.pagePhotos {
    margin-top: 2vw;
}
.rows {
    margin-top: 1vw;
}

.column {
    padding-bottom: 8px;
    font-size: 0;
    float: left;
    width: 33.33%;
}

.column + .column {
    padding-left: 8px;

}

.row::after {
    content: "";
    clear: both;
    display: table;
}

.column_Imgs {
    width:100%;
    height:250px;
    object-fit:cover;
}

Link{
    width: 150px;
}

.bookNowdiv {
    text-align: center;
    height: 40px;
    background-color: #f6cee0;
}

.bookNow {
    color: black;
    font-size: 20px;

}
.bookNow > i {
    margin-top: 10px;
}