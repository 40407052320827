* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*font-weight: 500;*/
  /*font-family: "Helvetica Neue";*/
  font-family: ".SF NS", 'Helvetica Neue';

}

