.contact_cards_row{
    margin: 2vw -1rem
}
.contact_cards_row:after {
    content: "";
    display: table;
    clear: both;
}
.contact_cards_column{
    float: left;
    width: 50%;
    padding: 0 1rem;
    align-items: center;
}



.contact_cards__item {
    list-style-type: none;
}

.contact_cards__item__link {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    color: black;
}

.contact_cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    height: 30vh;

}

.contact_cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact_pre--image {
    border-radius: 10px;
    overflow: hidden;
}

.contact_cards__item__text {
    position: absolute;
    top: 90%;
    text-align: center;
    left: 50%;
    transform: translate(-50%,-90%);
}

.contact_cards__item__h5 {
    font-size: 20px;
    padding: 10px;
}

@media screen and (max-width: 900px) {
    .contact_cards_column {
        width: 50%;
        display: block;
        padding-bottom: 2vw;
    }
    .contact_cards__item__h5 {
        font-size: 15px;
        padding: 10px;
    }
}