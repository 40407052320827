.search-title{
    margin: 3vw 6vw 0;
}
.searchContainer{
    margin-top: 2vw;
    height: 170px;
    background: #f6cee0;
    display: flex;
    justify-content: space-evenly;
}

.searchBox-labels{
    position: absolute;
    margin-top: 38px;
    font-size: 14px;
}

.searchBox-inputs{
    position: relative;
    margin-top: 42px;
    padding: 15px;
    outline: none;
    font-size: 12px;
    background: #fff;
}

::placeholder{
    color: rgb(105,105,105);
    font-weight: 1;
    font-size: 16px;
}

.Search-Btn{
    margin-top: 59px;
}

.Where-To-Input{
    width: 300px;
}
.toggletoggletoggle{
    background: #A7C7E7;
    z-index: 10;
    position: absolute;
    margin-top: 120px;
}

.fa-times-circle{
    background: transparent;
    color: black;
}

.select {
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    .Where-To-Input{
        width: 200px;
    }
}

@media screen and (max-width: 900px) {
    .noDisplay{
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .searchinner{
        display: none;
    }
}